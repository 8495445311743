<template>
	<div class="dropdown-wrapper" @click="toggle" :class="{active: activeDropdown}">
		<div class="nav-item-dropdown">
			<div class="nav-item-dropdown-header" ref="header">
				<base-font variant="menu" class="nav-position-name">
						{{item.name}}
				</base-font>
				<base-svg name="arrow_down" :class="{active: activeDropdown}" class="nav-arrow-icon"/>
			</div>
			<div class="wrapper" :style="activeDropdown ? wrapperHeight : false" ref="dropdown">
				<div class="inner-wrapper" ref="innerWrapper">
					<dropdown-group-items
						v-for="(element, i) in item.children"
						:key="element.name"
						:element="element"
						:index="i"
                        :expand="activeDropdown"
                        :isLast="i === item.children.length - 1 ? true : false"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import DropdownGroupItems from './DropdownGroupItems'
export default {
	data () {
		return {
			activeDropdown: false,
			isDesktop: false
		}
	},
	components: {
		DropdownGroupItems
	},
	props: {
		item: Object
	},
	methods: {
		countWrapperHeight () {
			return {
				height: this.$refs.innerWrapper.clientHeight + 'px'
			}
		},
		open () {
			this.activeDropdown = !this.activeDropdown
			this.wrapperHeight = this.countWrapperHeight()
			if (this.activeDropdown) {
				document.body.addEventListener('click', this.handleBodyClick)
				this.$root.$refs.menu_overlay.style.opacity = 1
			}
		},
		toggle () {
			if (this.activeDropdown) this.close()
			else this.open()
		},
		handleBodyClick (e) {
			if (this.$refs.dropdown.clientHeight) {
				this.close()
			}
		},
		close () {
			this.activeDropdown = false
			this.$root.$refs.menu_overlay.style.opacity = 0
			document.body.removeEventListener('click', this.handleBodyClick)
		}
	},
	mounted () {
		window.innerWidth >= 1200 ? this.isDesktop = true : this.isDesktop = false
	}
}
</script>
<style lang="scss" scoped>
.dropdown-wrapper {
    @include media-breakpoint-up(xl) {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
    }
    &.active {
        .nav-item-dropdown {
            &-header {
                .svg-icon.active {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .nav-item-dropdown {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.5rem 3.5rem;
            background: $secondary;
            font-weight: 500;
            position: relative;
            border-top: 1px solid $gray-600;
            border-bottom: 1px solid $gray-600;
            min-height: 54px;
            @include media-breakpoint-up(xl) {
                border: none;
                background: none;
                padding:0;
                flex-direction: column;
                justify-content: center;
            }
            .svg-icon {
                width: 10px;
                height: 5px;
                fill: $white;
                @include media-breakpoint-up(xl) {
                    position: absolute;
                    bottom: 0px;
                    &.active {
                        fill: $primary;
                    }
                }

                @include media-breakpoint-up(xxl) {
                    bottom: 7px;
                }
            }
        }
        .wrapper {
			cursor: default;
            height: 0;
            overflow: hidden;
            @include transition;
            @include media-breakpoint-up(xl) {
                background-color: $white;
                position: absolute;
                width: 100%;
                top: 105px;
                left: calc( 50% - 155px );
                border-radius: 3px;
                box-shadow: 0 3px 6px rgba($black, .16);
                width: 325px;
            }
            &.smaller-wrapper {
                @include media-breakpoint-up(xl) {
                    width: 60%;
                    .inner-wrapper {
                        padding: 0;
                    }
                    ::v-deep .child-icon {
                        height: 45px;
                        margin-bottom: 10px;
                    }
                }
            }
            .inner-wrapper {
                border-top: 1px solid $gray-600;
                border-bottom: 1px solid $gray-600;
                padding: 2.5rem 5.5rem;
                background: $secondary;
                display: flex;
                flex-direction: column;
                gap: 2.5rem;
                @include media-breakpoint-up(xl) {
                    border: none;
                    background: none;
                    align-items: center;
					padding: 2.5rem .5rem;
                }
            }
        }
    }
}
</style>
