<template>
	<section class="section-wrapper gallery-section">
		<grid-template cols="0" className="gallery-wrapper">
			<template #title>
				<base-heading tag="h2" :subheading="value.gallery_subtitle ? `${prefix}.gallery_subtitle` : null" :html="addStrong" class="section-header"/>
			</template>
			<figure class="gallery-image" @click="toggle(0)" ref="imageWrapper">
				<cms-image
					lazy
					:src="value.gallery_images[0].gallery_image"
					:base="{width: 360, heigth: 95}"
					:lg="{width: 330, height: 200}"
					:xl="{width: 1200, height: 400}"
					class="gallery-image-item"
				/>
			</figure>
		</grid-template>
		<gallery-modal v-if="isModalOpen"  :images="value.gallery_images" :activeImageIndex="activeImageIndex" :handleModalWrapperClick="handleModalWrapperClick">
			<button-close @close="toggle" />
		</gallery-modal>
	</section>
</template>
<script>
import GridTemplate from '@f/components/GridTemplate/GridTemplate.vue'
import GalleryModal from '../../components/organisms/GalleryModal/GalleryModal.vue'
import ButtonClose from '~/website/front/components/molecules/buttons/ButtonClose/ButtonClose.vue'
import BaseHeading from '~/website/front/components/atoms/BaseHeading/BaseHeading.vue'
export default {
	components: {
		GalleryModal,
		GridTemplate,
		ButtonClose,
		BaseHeading
	},
	props: {
		prefix: String,
		value: Object
	},
	data: () => ({
		activeImageIndex: 0,
		isModalOpen: false
	}),
	computed: {
		activeImageSrc () {
			return this.value.gallery_images[this.activeImageIndex].gallery_image
		},
		addStrong () {
			return this.value.gallery_title.split('<<').join('<span class="strong">').split('>>').join('</span>')
		}
	},
	methods: {
		toggle (id) {
			this.activeImageIndex = id
			this.isModalOpen = !this.isModalOpen
			if (this.isModalOpen) {
				this.$root.$refs.menu.classList.add('modal-open')
			} else {
				this.$root.$refs.menu.classList.remove('modal-open')
			}
		},
		slideAnimation () {
			this.$refs.imageWrapper.animate([
				{ opacity: 0 }, { opacity: 1 }
			], 1000)
		},
		handleModalWrapperClick () {
			this.$root.$refs.menu.classList.remove('modal-open')
			this.isModalOpen = false
		}
	},
	mounted () {
		console.log('xddd', this.value)
	}

}
</script>
<style lang="scss" scoped>

.section-wrapper {
	z-index: initial;
	@include media-breakpoint-up(xl) {
		background: $white;
	}
	::v-deep .section-header {
		margin-bottom: 4rem;
		@include media-breakpoint-up(xl) {
			margin-bottom: 6rem;
		}
	}
	.gallery-image {
		display: flex;
		justify-content: center;
		border-radius: 5px;
		overflow: hidden;
		cursor: pointer;
		@include transition;
		position: relative;
		@include media-breakpoint-up(xl) {
			&:hover {
				transform: translateY(-10px);
			}
		}
		&-item {
			height: 100%;
		}

		.room-number {
			position: absolute;
			padding: 0.5rem 2rem;
			top: 1rem;
			right: 1rem;
			background-color: $white;

			::v-deep .text {
				font-size: 1.4rem;
				text-transform: uppercase;
				margin-bottom: 0;
				color: $primary;
				font-weight: 600;
			}
		}
	}
	::v-deep .gallery-image-item .base-image {
		max-width: 100%;
		display: flex;
		height: 100%;
		object-fit: cover;
	}
	::v-deep .gallery-wrapper {
		gap: 1rem;
		grid-template-columns: repeat(1, 1fr);
		@include media-breakpoint-up(lg) {
			grid-template-columns: repeat(1, 1fr);
		}
		@include media-breakpoint-up(xl) {
			gap: 3rem;
		}
	}
}
</style>
